
.seminar-list-item{
    &__subject{
        white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
    }
    &__summary{
        max-height: 3em;
        overflow: hidden; text-align: left; text-overflow: ellipsis; white-space: normal; word-wrap: break-word; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
    }
}
